import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Layout from './Layout';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Dashboard from './Pages/Dashboard';
import Devices from './Pages/Devices';
import Preview from './Pages/Preview';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout><Dashboard /></Layout>
  },
  {
    path: "/devices",
    element: <Layout><Devices /></Layout>
  },
  {
    path: "/preview",
    element: <Layout><Preview /></Layout>
  }
]);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
