import { CiLock, CiUnlock } from 'react-icons/ci'

function Selector({ list, selectedState }) {

    const setSelected = (selection) => selectedState[1](selection)

    return <div className="ta flex flex-col flex-wrap gap-y-2 mt-2">
        {
            list.map(elem => <button onClick={() => setSelected(elem.serialNumber)} className="group text-xs flex flex-row items-start bg-neutral-800 text-neutral-200 overflow-hidden rounded-lg cursor-pointer hover:rounded-xl hover:bg-black focus:rounded-xl focus:bg-black w-full" key={elem.serialNumber}>
                <span style={{ backgroundColor: elem.color }} className="w-3 h-full group-hover:w-12 group-focus:w-12 group-hover:p-2 group-focus:p-2">
                    {' '}
                </span>
                <div className='flex flex-col w-full p-2 pr-1 gap-y-1'>
                    <div className="flex justify-start gap-x-1 w-full items-center">
                        <p>
                            {elem.assignedTo || "Nicht zugewiesen"}
                        </p>
                        <span className="justify-self-end self-end ml-auto">
                            {
                                !elem.disabled ? <CiUnlock className="h-4 w-4  opacity-30 hover:opacity-100 group-hover:opacity-90 group-focus:opacity-90 text-white mx-1 rounded-full" /> : <CiLock className="h-4 w-4  opacity-30 hover:opacity-100 group-hover:opacity-90 group-focus:opacity-90 text-white mx-1 rounded-full" />
                            }
                        </span>
                    </div>
                    <div className="flex flex-row justify-between w-full">
                        <p className="scale-90 opacity-50 group-hover:opacity-90 group-focus:opacity-90 text-nowrap">
                            {elem.displayName}
                        </p>
                        <p className="bg-white text-black px-3 scale-90 rounded-full opacity-50 group-hover:opacity-90 group-focus:opacity-90 text-nowrap">
                            {elem.assignedPage || "Keine Vorlage"}
                        </p>
                    </div>
                </div>

            </button>)
        }
    </div>
}

export default Selector