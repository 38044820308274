function BusinessNumbers() {

    return <div className="flex flex-row flex-wrap gap-y-1">
        <span className="w-1/3 p-1 h-24 ">
            <div className="h-full border text-center flex flex-col justify-end pb-3 px-2">
                <p className="text-xs uppercase tracking-widers h-full items-center pt-5 justify-center flex" >Insgesamt</p>
                <p className="text-xl">
                    512
                </p>
            </div>
        </span>
        <span className="w-1/3 p-1 h-24 ">
            <div className="h-full border text-center flex flex-col justify-end pb-3 px-2">
                <p className="text-xs uppercase tracking-widers h-full items-center pt-5 justify-center flex" >30 Tage</p>
                <p className="text-xl">
                    132
                </p>
            </div>
        </span>
        <span className="w-1/3 p-1 h-24 ">
            <div className="h-full border text-center flex flex-col justify-end pb-3 px-2">
                <p className="text-xs uppercase tracking-widers h-full items-center pt-5 justify-center flex" >7 Tage</p>
                <p className="text-xl">
                    21
                </p>
            </div>
        </span>
        <span className="w-1/3 p-1 h-24 ">
            <div className="h-full border text-center flex flex-col justify-end pb-3 px-2">
                <p className="text-xs uppercase tracking-widers h-full items-center pt-5 justify-center flex" >Heute</p>
                <p className="text-xl">
                    12
                </p>
            </div>
        </span>
        <span className="w-1/3 p-1 h-24 ">
            <div className="h-full border text-center flex flex-col justify-end pb-3 px-2">
                <p className="text-xs uppercase tracking-widers h-full items-center pt-5 justify-center flex" >Riwan</p>
                <p className="text-xl">
                    6
                </p>
            </div>
        </span>

        <span className="w-1/3 p-1 h-24 ">
            <div className="h-full border text-center flex flex-col justify-end pb-3 px-2">
                <p className="text-xs uppercase tracking-widers h-full items-center pt-5 justify-center flex" >Güney</p>
                <p className="text-xl">
                    6
                </p>
            </div>
        </span>

    </div>
}

export default BusinessNumbers