import { useRef } from 'react'
import { Link } from 'react-router-dom'

function PrimaryMenu() {
  const menu = [
    {
      url: "/",
      text: "Dashboard",
    },
    {
      url: "/stats",
      text: "Statistik",
    },
    {
      url: "/devices",
      text: "Geräte",
    },
    {
      url: "/preview",
      text: "Vorschau"
    },
    {
      url: "/settings",
      text: "Einstellungen",
    },
  ]

  return <ul>
    {
      menu.map((item, idx) => <li key={idx}><Link to={item.url}>{item.text}</Link></li>)
    }
  </ul>
}

function FooterMenu() {
  const menu = [
    {
      url: "https://www.tiptap.com",
      text: "Terms and conditions",
    },
    {
      url: "/privacy",
      text: "Privacy",
    },
    {
      url: "https://bitrate-it.de",
      text: "Visit tiptap.com",
    }
  ]

  return <ul>
    {
      menu.map((item, idx) => <li key={idx}><Link to={item.url}>{item.text}</Link></li>)
    }
  </ul>
}

function Layout({ children }) {
  const navRef = useRef(null)

  const toggleOpened = () => navRef.current.classList.contains('closed') ? navRef.current.classList.remove('closed') : navRef.current.classList.add('closed')

  return (
    <>
      <img onClick={toggleOpened} width={60} src="TipTagLogo.svg" alt="TipTag Logo" className='absolute top-0 left-0 z-50 block md:hidden cursor-pointer' />

      <header ref={navRef} className="closed">
        <div className="_container">
          <img width={60} src="TipTagLogo.svg" alt="TipTag Logo" className='hidden md:block' />
          <nav>
            <PrimaryMenu />
          </nav>
        </div>
      </header>
      <main>
        {children}
      </main>
      <footer>
        <div className="_container">
          <img width={100} height={100} src="TipTagLogo.svg" alt="TipTag Logo" />
          <div className="flex text-center md:text-left flex-col md:flex-row gap-x-20 gap-y-10">
            <nav>
              <h3>Hauptmenü</h3>
              <PrimaryMenu />
            </nav>
            <nav>
              <h3>Weitere Links</h3>
              <FooterMenu />
            </nav>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Layout;
