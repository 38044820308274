import { CiPalette, CiPen, CiUser, CiLock, CiSquareCheck } from 'react-icons/ci'

function SelectorDetails({ detailsArray }) {



    if (detailsArray.length === 0) {
        return <div className="ta flex flex-col justify-center item-center text-center gap-y-2 mt-2 w-full h-full p-2 rounded bg-neutral-900 text-white text-sm">
            <p className='text-sm uppercase font-thin text-wrap tracking-widest'>
                Kein Wristband ausgewählt
            </p>
        </div>
    }

    const details = detailsArray[0]

    return <div className="ta border bg-neutral-900 text-white text-sm shadow-md overflow-hidden flex flex-col gap-y-2 mt-2 w-full h-full p-2 pb-4 rounded">
        <span className='text-xs opacity-50 w-full flex flex-row justify-between items-center'>
            <p>
                {details.displayName}
            </p>
            <span className='flex flex-row gap-1'>
                <p>
                    S/N {details.serialNumber}
                </p>
                <button>
                    <CiLock className='w-4 h-4' />
                </button>
            </span>
        </span>
        <form className='px-2 flex flex-col gap-2'>
            <span className='text-xs w-full flex flex-row justify-between items-center'>
                <p className='text-neutral-400'>Zuweisung</p>
                <button>
                    <CiSquareCheck className='w-4 h-4' />
                </button>
            </span>
            <span className='flex flex-row items-center'>
                <CiUser className='h-5 w-5 ml-3 opacity-50 border-r pr-1 absolute' />
                <input type="text" placeholder='Name des Mitarbeiters' value={details?.assignedUser?.name || ""} className='py-3 pl-10 bg-neutral-800 rounded-md w-full' />
            </span>
            <span className='text-xs w-full flex flex-row justify-between items-center'>
                <p className='text-neutral-400'>Weiterleitung</p>
            </span>
            <span className='flex flex-row items-center'>
                <CiPen className='h-5 w-5 ml-3 opacity-50 border-r pr-1 absolute' />
                <input type="text" placeholder='Titel' value={details?.assignedPage?.title || ""} className='py-3 pl-10 bg-neutral-800 rounded-md w-full' />
            </span>
            <span className='flex flex-row items-center'>
                <CiPen className='h-5 w-5 ml-3 opacity-50 border-r pr-1 absolute' />
                <input type="text" placeholder='Untertitel' value={details?.assignedPage?.subtitle || ""} className='py-3 pl-10 bg-neutral-800 rounded-md w-full' />
            </span>
            <span className='flex flex-row items-center'>
                <CiPen className='h-5 w-5 ml-3 opacity-50 border-r pr-1 absolute' />
                <input type="text" placeholder='Text' value={details?.assignedPage?.description || ""} className='py-3 pl-10 bg-neutral-800 rounded-md w-full' />
            </span>
            <span className='flex flex-col md:flex-row gap-2 '>

                <span className='flex flex-row items-center'>
                    <CiPalette className='h-5 w-5 ml-3 opacity-50 border-r pr-1 absolute' />
                    <input type="text" placeholder='Primärfarbe' value={details?.assignedPage?.primaryColor || ""} className='py-3 pl-10 bg-neutral-800 rounded-md w-full' />
                </span>
                <span className='flex flex-row items-center'>
                    <CiPalette className='h-5 w-5 ml-3 opacity-50 border-r pr-1 absolute' />
                    <input type="text" placeholder='Sekundärfarbe' value={details?.assignedPage?.secondaryColor || ""} className='py-3 pl-10 bg-neutral-800 rounded-md w-full' />
                </span>
            </span>
        </form>

    </div>
}

export default SelectorDetails