import { useState } from "react";
import Selector from "../Widgets/Selector";
import SelectorDetails from "../Widgets/SelectorDetails";

function Devices() {

    const [selectedDevice, setSelectedDevice] = useState(null)

    const deviceData = [
        {
            serialNumber: "123-123-123-1",
            displayName: "Hopperfield",
            color: "rgb(90,42,200)",
            assignedUser: {
                id: "123123123",
                name: "Abdullah",
                image: ""
            },
            assignedPage: {
                pageId: "1",
                pageName: "New Template",
                title: "Hey!",
                subtitle: "Danke für's Scannen!",
                description: "Damit hilfst du uns echt weiter, yo!",
                primaryColor: "blue",
                secondaryColor: "red"
            }
        },
        {
            serialNumber: "123-123-123-2",
            displayName: "Tiwrly Rose",
            color: "rgb(50,50,50)",
            disabled: true
        },
        {
            serialNumber: "123-123-123-3",
            displayName: "Rockstar",
            color: "rgb(170,200,100)",
            assignedUser: {
                id: "123123123",
                name: "Omar",
                image: ""
            },
        },
        {
            serialNumber: "123-123-123-4",
            displayName: "Beethoven",
            color: "rgb(220,220,220)",
            assignedPage: {
                pageId: "1",
                pageName: "New Template",
                title: "Hey!",
                subtitle: "Danke für's Scannen!",
                description: "Damit hilfst du uns echt weiter, yo!",
                primaryColor: "blue",
                secondaryColor: "red"
            }
        },
    ]

    return <>
        <section>
            <h1>Geräte</h1>
        </section>
        <section>
            <h2>Wristbands</h2>
            <aside>
                <article>
                    <h3>Wähle ein Wristband</h3>
                    <Selector selectedState={[selectedDevice, setSelectedDevice]} list={deviceData.map(elem => { return { serialNumber: elem.serialNumber, displayName: elem.displayName, color: elem.color, assignedTo: elem?.assignedUser?.name, assignedPage: elem?.assignedPage?.pageName, lastUsed: "12.12.2023", disabled: elem?.disabled || false } })} />
                </article>
            </aside>
            <aside>
                <article>
                    <SelectorDetails detailsArray={deviceData.filter(d => d.serialNumber === selectedDevice)} />
                </article>
            </aside>
        </section>

    </>
}

export default Devices;