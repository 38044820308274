import { CiFaceFrown, CiFaceMeh, CiFaceSmile, CiHeart } from 'react-icons/ci';
import { FaArrowRight, FaGoogle, FaPen } from 'react-icons/fa6'
import gsap from "gsap";
import { useEffect, useState } from 'react';
import SplitTextJS from "split-text-js/SplitTextJS"
import React from 'react';

function Preview() {

    const [answers, setAnswers] = useState({ init: 0 })
    const [currentQuestion, setCurrentQuestion] = useState(0)

    useEffect(() => {
        const tl = gsap.timeline()

        const lines = gsap.utils.toArray('.line')
        lines.forEach(_line => {
            const line = new SplitTextJS(_line)
            tl.from(line.chars, { delay: .8, y: 10, rotateX: -90, opacity: 0, stagger: .01 }, "<")
                .to(line.chars, { y: -10, rotateX: 90, opacity: 0, stagger: .01 }, "<1.8")

        })

        const init = gsap.utils.toArray('.q-start')
        init.forEach(line => {
            tl.from(line, { y: 100, opacity: 0 }, "<.5")
                .to(line, { y: 0, opacity: 1 }, "<")
        })

    }, [])


    const tl = gsap.timeline()


    const feedbackScore = () => {
        let score = 0
        Object.keys(answers).filter(a => a.startsWith('feedback')).forEach(key => {
            score += answers[key]
        })
        return score
    }

    const handleAnswer = (name, value) => {
        if (name === "action")
            return

        setAnswers(prev => { return { ...prev, [name]: value } })
        setCurrentQuestion(prev => prev + 1)

        if (currentQuestion === 3) {
            const lines = gsap.utils.toArray('.ty-line')
            lines.forEach(_line => {
                const line = new SplitTextJS(_line)
                tl.from(line.chars, { delay: .8, y: 10, rotateX: -90, opacity: 0, stagger: .01 }, "<")
                    .to(line.chars, { y: -10, rotateX: 90, opacity: 0, stagger: .01 }, "<1.8")

            })
            const init = gsap.utils.toArray('.ty-box')
            init.forEach(line => {
                tl.from(line, { y: 100, opacity: 0 }, "<.5")
                    .to(line, { y: 0, opacity: 1 }, "<")
            })
        }
    }

    return <>
        <div className="w-screen h-screen absolute top-0 left-0 bg-white z-50 overflow-hidden pb-10">
            <div className='h-full w-full flex flex-col justify-center items-center'>
                <p className='line absolute text-7xl font-extralight'>Hello there!</p>
                <p className='line absolute text-2xl font-extralight'>Thank you for scanning the TipTag</p>
                <p className='line absolute text-2xl font-extralight'>You will get a little gift afterwards.</p>
                <p className='line absolute text-6xl font-black'>Let's start!</p>
                <div className='w-full p-2 transition-all absolute delay-200 duration-200' style={{ marginLeft: currentQuestion < 0 ? "200%" : (currentQuestion === 0 ? 0 : "-200%") }}>
                    <p className='q-start italic py-3 text-xl text-center'>Are you visiting us for the first time?</p>
                    <div className='q-start flex flex-row gap-2 h-48'>
                        <div onClick={() => handleAnswer('newCustomer', true)} className='h-full cursor-pointer w-full p-1 border shadow-sm rounded-md group transition-all hover:bg-neutral-100 text-neutral-600 hover:text-black bg-neutral-50 flex flex-col justify-center items-center'>
                            <CiHeart className='w-24 h-24 p-8 group-hover:p-2 my-3 group-hover:my-0 transition-all group-hover:text-red-400 group-active:text-red-400' />
                            <p className='transition-all text-sm'>Yes, first time.</p>
                        </div>
                        <div onClick={() => handleAnswer('newCustomer', false)} className='h-full cursor-pointer w-full p-1 border shadow-sm rounded-md group transition-all hover:bg-neutral-100 text-neutral-600 hover:text-black bg-neutral-50 flex flex-col justify-center items-center'>
                            <CiHeart className='w-24 h-24 p-3 group-hover:p-2 my-3 group-hover:my-0 transition-all group-hover:text-red-400 group-active:text-red-400' />
                            <p className='transition-all text-sm'>It's not my first time.</p>
                        </div>
                    </div>
                </div>

                <div style={{ opacity: (currentQuestion === 4 || currentQuestion === 0) ? 0 : 1 }} className='max-w-52 w-full flex flex-row rounded-xl h-3 overflow-hidden p-0 mx-auto absolute left-1/2 -translate-x-1/2 top-36 bg-neutral-200 transition-all duration-200 delay-750'>
                    <span className='bg-neutral-400 p-0 h-full transition-all duration-700' style={{ width: `${100 * (currentQuestion / 4)}%` }}>.</span>
                </div>

                <div className='w-full p-2 transition-all absolute duration-200 delay-200' style={{ marginLeft: currentQuestion < 1 ? "200%" : (currentQuestion === 1 ? 0 : "-200%") }}>
                    <p className='italic py-3 text-xl text-center'>How was the food?</p>
                    <div className='flex flex-row gap-2 h-48'>
                        <div onClick={() => handleAnswer('feedbackFood', 2)} className='h-full cursor-pointer w-full p-1 border shadow-sm rounded-md group transition-all hover:bg-neutral-100 text-neutral-600 hover:text-black bg-neutral-50 flex flex-col justify-center items-center'>
                            <CiFaceFrown className='w-24 h-24 p-3 group-hover:p-2 my-3 group-hover:my-0 transition-all group-hover:text-red-400 group-active:text-red-400' />
                            <p className='transition-all text-sm'>Bad</p>
                        </div>
                        <div onClick={() => handleAnswer('feedbackFood', 1)} className='h-full cursor-pointer w-full p-1 border shadow-sm rounded-md group transition-all hover:bg-neutral-100 text-neutral-600 hover:text-black bg-neutral-50 flex flex-col justify-center items-center'>
                            <CiFaceMeh className='w-24 h-24 p-3 group-hover:p-2 my-3 group-hover:my-0 transition-all group-hover:text-orange-400 group-active:text-orange-400' />
                            <p className='transition-all text-sm'>Ok</p>
                        </div>
                        <div onClick={() => handleAnswer('feedbackFood', 0)} className='h-full cursor-pointer w-full p-1 border shadow-sm rounded-md group transition-all hover:bg-neutral-100 text-neutral-600 hover:text-black bg-neutral-50 flex flex-col justify-center items-center'>
                            <CiFaceSmile className='w-24 h-24 p-3 group-hover:p-2 my-3 group-hover:my-0 transition-all group-hover:text-green-400 group-active:text-green-400' />
                            <p className='transition-all text-sm'>Good</p>
                        </div>
                    </div>
                    <p className='uppercase font-extralight tracking-wider text-xs p-5 active:font-thin' onClick={() => setCurrentQuestion(prev => prev - 1)}>Step back</p>
                </div>

                <div className='w-full p-2 transition-all absolute duration-200 delay-200' style={{ marginLeft: currentQuestion < 2 ? "200%" : (currentQuestion === 2 ? 0 : "-200%") }}>
                    <p className='italic py-3 text-xl text-center'>How was the Service?</p>
                    <div className='flex flex-row gap-2 h-48'>
                        <div onClick={() => handleAnswer('feedbackService', 2)} className='h-full cursor-pointer w-full p-1 border shadow-sm rounded-md group transition-all hover:bg-neutral-100 text-neutral-600 hover:text-black bg-neutral-50 flex flex-col justify-center items-center'>
                            <CiFaceFrown className='w-24 h-24 p-3 group-hover:p-2 my-3 group-hover:my-0 transition-all group-hover:text-red-400 group-active:text-red-400' />
                            <p className='transition-all text-sm'>Bad</p>
                        </div>
                        <div onClick={() => handleAnswer('feedbackService', 1)} className='h-full cursor-pointer w-full p-1 border shadow-sm rounded-md group transition-all hover:bg-neutral-100 text-neutral-600 hover:text-black bg-neutral-50 flex flex-col justify-center items-center'>
                            <CiFaceMeh className='w-24 h-24 p-3 group-hover:p-2 my-3 group-hover:my-0 transition-all group-hover:text-orange-400 group-active:text-orange-400' />
                            <p className='transition-all text-sm'>Ok</p>
                        </div>
                        <div onClick={() => handleAnswer('feedbackService', 0)} className='h-full cursor-pointer w-full p-1 border shadow-sm rounded-md group transition-all hover:bg-neutral-100 text-neutral-600 hover:text-black bg-neutral-50 flex flex-col justify-center items-center'>
                            <CiFaceSmile className='w-24 h-24 p-3 group-hover:p-2 my-3 group-hover:my-0 transition-all group-hover:text-green-400 group-active:text-green-400' />
                            <p className='transition-all text-sm'>Good</p>
                        </div>
                    </div>
                    <p className='uppercase font-extralight tracking-wider text-xs p-5 active:font-thin' onClick={() => setCurrentQuestion(prev => prev - 1)}>Step back</p>
                </div>

                <div className='w-full p-2 transition-all absolute duration-200 delay-200' style={{ marginLeft: currentQuestion < 3 ? "200%" : (currentQuestion === 3 ? 0 : "-200%") }}>
                    <p className='italic py-3 text-xl text-center'>How was the atomsphere?</p>
                    <div className='flex flex-row gap-2 h-48'>
                        <div onClick={() => handleAnswer('feedbackAtmosphere', 2)} className='h-full cursor-pointer w-full p-1 border shadow-sm rounded-md group transition-all hover:bg-neutral-100 text-neutral-600 hover:text-black bg-neutral-50 flex flex-col justify-center items-center'>
                            <CiFaceFrown className='w-24 h-24 p-3 group-hover:p-2 my-3 group-hover:my-0 transition-all group-hover:text-red-400 group-active:text-red-400' />
                            <p className='transition-all text-sm'>Bad</p>
                        </div>
                        <div onClick={() => handleAnswer('feedbackAtmosphere', 1)} className='h-full cursor-pointer w-full p-1 border shadow-sm rounded-md group transition-all hover:bg-neutral-100 text-neutral-600 hover:text-black bg-neutral-50 flex flex-col justify-center items-center'>
                            <CiFaceMeh className='w-24 h-24 p-3 group-hover:p-2 my-3 group-hover:my-0 transition-all group-hover:text-orange-400 group-active:text-orange-400' />
                            <p className='transition-all text-sm'>Ok</p>
                        </div>
                        <div onClick={() => handleAnswer('feedbackAtmosphere', 0)} className='h-full cursor-pointer w-full p-1 border shadow-sm rounded-md group transition-all hover:bg-neutral-100 text-neutral-600 hover:text-black bg-neutral-50 flex flex-col justify-center items-center'>
                            <CiFaceSmile className='w-24 h-24 p-3 group-hover:p-2 my-3 group-hover:my-0 transition-all group-hover:text-green-400 group-active:text-green-400' />
                            <p className='transition-all text-sm'>Good</p>
                        </div>
                    </div>
                    <p className='uppercase font-extralight tracking-wider text-xs p-5 active:font-thin' onClick={() => setCurrentQuestion(prev => prev - 1)}>Step back</p>
                </div>

                <div className='ty w-full p-2 transition-all absolute duration-200 delay-200' style={{ marginLeft: currentQuestion < 4 ? "200%" : (currentQuestion === 4 ? 0 : "-200%") }}>

                    <p className='ty-line absolute text-6xl text-center w-full font-black'>Thanks!</p>
                    <p className='ty-line absolute text-2xl text-center w-full font-extralight'>You will get a digital fortune cookie..</p>
                    <p className='ty-line absolute text-2xl text-center w-full font-extralight'>..just complete the final step.</p>

                    <div className='ty-box flex flex-col gap-2 h-48'>
                        <div style={{ display: feedbackScore() < 2 ? 'flex' : 'none' }} onClick={() => handleAnswer('action', "external")} className='ty cursor-pointer w-full p-1  rounded-md group transition-all text-neutral-600 hover:text-black flex flex-col justify-start'>
                            <span className='w-full h-full flex flex-row justify-start items-center '>
                                <FaGoogle className='w-8 h-8  px-1 mx-2 mr-4 transition-all ' />
                                <p className='transition-all text-xl'>Final Step</p>
                                <FaArrowRight className='w-8 h-8  px-1 mx-2 ml-auto transition-all translate-y-1/2' />
                            </span>
                            <span className='flex flex-col text-black'>
                                <p className='ty text-xs font-thin text-left mt-1'>For the digital fortune cookie continue with this final step</p>
                                <p className='ty text-xs font-thin text-left'>Don't forget to scan the TipTap again when you're done!</p>
                            </span>
                        </div>
                        <div style={{ display: feedbackScore() < 2 ? 'none' : 'flex' }} onClick={() => handleAnswer('action', "internal")} className='ty cursor-pointer w-full p-1  rounded-md group transition-all text-neutral-600 hover:text-black flex flex-col justify-start'>
                            <span className='w-full h-full flex flex-row justify-start items-center '>
                                <FaPen className='w-8 h-8  px-1 mx-2 mr-4 transition-all ' />
                                <p className='transition-all text-xl'>Let us know what we can do better</p>
                                <FaArrowRight className='w-8 h-8  px-1 mx-2 ml-auto transition-all translate-y-1/2' />
                            </span>
                            <span className='flex flex-col text-black'>
                                <p className='ty text-xs font-thin text-left mt-1'>For the digital fortune cookie continue with this final step</p>
                                <p className='ty text-xs font-thin text-left'>Leave a comment or a complaint</p>
                            </span>
                        </div>

                    </div>
                </div>


            </div>
            <ul className="z-50 absolute bottom-0 left-0 list-none flex flex-row w-full p-1 justify-between text-neutral-400 text-sm mb-2">
                <li>
                    <a className='p-3' href="/cookies">Cookies</a>
                </li>
                <li className='mr-auto'>
                    <a className='p-3' href="/cookies">Privacy</a>
                </li>
                <li><a className='p-3' href="/cookies">Visit tiptap.com</a></li>

            </ul>
        </div >
    </>
}

export default Preview