import BusinessNumbers from "../Widgets/BusinessNumber";

function Dashboard() {
    return <>
        <section>
            <h1>Dashboard</h1>
        </section>
        <section>
            <h2>Mitarbeiter</h2>
            <aside>
                <article>
                    <h3>
                        BusinessNumbers
                    </h3>
                    <BusinessNumbers />
                </article>
            </aside>
            <aside>
                <article>
                    <h3>
                    </h3>
                </article>
            </aside>
            <article>
                <h3>
                </h3>
            </article>
        </section>

    </>
}

export default Dashboard;